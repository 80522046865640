import React from "react";
import { Checkbox, FormControlLabel, Box } from "@mui/material";
import { styled } from "@mui/system";
import { Option } from "../types";

const ActiveLabel = styled(FormControlLabel)(
  ({ isActive }: { isActive: boolean }) => ({
    borderRadius: "4px",
    padding: "5px 10px",
    margin: "5px",
    transition: "background-color 0.3s",
  })
);

interface InlineCheckboxGroupProps {
  options: Option[];
  selectedOptions: string[];
  onOptionChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const InquirySelector: React.FC<InlineCheckboxGroupProps> = ({
  options,
  selectedOptions,
  onOptionChange,
}) => {
  console.log("fadsfsadfasdfas", options);
  return (
    <Box display="flex" flexDirection="row" gap="10px">
      {options.map((option) => (
        <ActiveLabel
          key={option.name}
          isActive={selectedOptions.includes(option.name)}
          control={
            <Checkbox
              checked={selectedOptions.includes(option.name)}
              onChange={onOptionChange}
              value={option.name}
            />
          }
          label={option.name}
        />
      ))}
    </Box>
  );
};

export default InquirySelector;
