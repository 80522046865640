import React from "react";

const InvoicePassengers = ({ invoice }: any) => {
  console.log("Invoice Data: ", invoice);

  const stayPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "stay"
    ) || [];
  const carPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "car"
    ) || [];
  const trainPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "train"
    ) || [];
  const flightPassengers =
    invoice?.invoice_suggestions?.filter(
      (suggestion: any) => suggestion.type === "flights"
    ) || [];
  console.log("asdmnamasdmnasmdnasmdnas", stayPassengers);
  return (
    <>
      {stayPassengers.length > 0 && (
        <>
          {stayPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.stay_inq_suggestion?.stay_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.stay_inq_room?.booked_by || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
      {carPassengers.length > 0 && (
        <>
          {carPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.car_inq_suggestion?.car_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.car_inq_passenger?.first_name || "N/A"}{" "}
                      {cost?.car_inq_passenger?.last_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}

      {trainPassengers.length > 0 && (
        <>
          {trainPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.train_inq_suggestion?.train_inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.train_inq_passenger?.first_name || "N/A"}{" "}
                      {cost?.train_inq_passenger?.last_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
      {flightPassengers.length > 0 && (
        <>
          {flightPassengers.map((item: any, index: number) => (
            <div
              key={index}
              className="self-stretch flex flex-row items-start justify-between gap-[20px] mq450:flex-wrap"
            >
              {/* Ensure that car_inq_suggestion_costs is correctly accessed */}
              {item?.inq_suggestion?.inq_suggestion_costs?.map(
                (cost: any, costIndex: number) => (
                  <div
                    key={costIndex}
                    className="flex flex-row justify-between w-full"
                  >
                    <div className="relative leading-[14px]">
                      {cost?.inq_passenger?.first_name || "N/A"}{" "}
                      {cost?.inq_passenger?.last_name || "N/A"}
                    </div>
                    <div className="relative leading-[14px] font-medium text-right inline-block min-w-[50px] whitespace-nowrap">
                      £{cost?.sale_price}
                    </div>
                  </div>
                )
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default InvoicePassengers;
