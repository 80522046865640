import React from "react";

import { splitLocation } from "~/utils/splitLocation";
const StayCard = ({
  location,
  startDate,
  endDate,
  rooms,
  adults,
  children,
  height,
  detail,
}: any) => {
  const { cityName: locationName, remainingAddress: locationAddress } =
    splitLocation(location);

  // console.log("asdnasmbdams", adults);
  const locationWords = locationName.split(" ");
  const firstWord = locationWords[0];
  const remainingWords = locationWords.slice(1).join(" ");

  return (
    <div
      className={`flex-1 w-full rounded-2xl shadow bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow min-h-[${height}] flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
    `}
    >
      <div className="w-full text-primary dark:text-dark-primary flex flex-col gap-3.5 justify-between rounded-2xl h-full">
        <div className="self-stretch flex flex-row pb-2.5 pt-10 px-[30px]  items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-[1px]">
            <div className="self-stretch relative text-7xl font-medium text-basic dark:text-dark-primary">
              {firstWord}
            </div>
            {/* {remainingWords && (
              <div className="self-stretch relative text-sm font-medium text-basic dark:text-dark-primary">
                {remainingWords} {" "} 
              </div>
            )} */}
            {remainingWords && (
              <div className="self-stretch relative font-medium text-basic dark:text-dark-primary">
                {remainingWords} &nbsp; {locationAddress}
              </div>
            )}
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary">
              {locationAddress}
            </div>
            <div className="flex justify-between w-full my-3">
              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                {startDate}
              </div>
              <div className="relative font-medium text-basic dark:text-dark-primary text-base">
                {endDate}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-1 md:flex-row px-[30px] py-7 items-center justify-between bg-basic rounded-b-2xl">
        <div className="flex flex-col items-start justify-center w-full">
          <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-base">
              Rooms: {rooms}
            </div>
            <div className="self-stretch relative text-base">
              Adults:{adults}
            </div>
            <div className="self-stretch relative text-base">
              Children: {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayCard;
