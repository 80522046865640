import moment from "moment";
import React, { useState } from "react";
import ViewIcon from "modules/InquiriesModule/segments/InquiryDetail/assets/svg/ViewIcon";
import { useUserDefaultRole } from "@nhost/react";
import { URLs } from "~/config/enums";
import PaymentEdit from "./components/PaymentEdit";
import { FaTimes } from "react-icons/fa";
import RefundModal from "./components/RefundModal";
import { MdDelete } from "react-icons/md";
import { useMutation } from "@apollo/client";
import { DELETE_TRANSACTION } from "~/modules/AccountModule/segments/PaymentRequest/components/PaymentRecord/graphql/Mutation";
import { toast } from "react-toastify";
import { BiSolidFilePdf } from "react-icons/bi";
import SideDrawer from "~/components/common/sideDrawer";

const PaymentCard = ({ transaction, inquiry, hideActions }: any) => {
  const [showReceipt, setShowReceipt] = useState(false);
  const role = useUserDefaultRole();

  const [deleteTransaction] = useMutation(DELETE_TRANSACTION);

  const [editForm, setEditForm] = useState(false);
  const [openRefMod, setOpenRefMod] = useState(false);

  return (
    <>
      {!editForm ? (
        <>
          <div className="rounded-lg shadow">
            <div className="self-stretch flex-1 bg-white dark:bg-dark-secondary flex flex-col p-[25px] items-start gap-10 text-left text-mini rounded-t-lg shadow text-darkslategray dark:text-dark-primary">
              <div className="self-stretch flex flex-row items-center justify-between">
                {/* <div className="relative">
                <span>Reference #</span>
                <b>{transaction?.ref_no}</b>
              </div> */}
                <div className="relative flex gap-1">
                  <span>Transaction</span>
                  <b>{`#${transaction?.transactions_no}`}</b>
                </div>

                <div className="flex flex-row items-center justify-end gap-3">
                  {transaction?.acc_payment_method?.type === "bank" &&
                    !hideActions && (
                      <button onClick={() => setShowReceipt(true)}>
                        <BiSolidFilePdf className="text-basic dark:text-dark-primary h-6 w-6" />
                      </button>
                    )}
                  {role === "admin" && (
                    <MdDelete
                      className="text-basic cursor-pointer dark:text-dark-primary  h-6 w-6 "
                      onClick={async () => {
                        const res = await deleteTransaction({
                          variables: {
                            id: transaction.id,
                          },
                        });

                        if (res.data.delete_inq_transection.returning?.[0]?.id) {
                          toast.success("Transaction Deleted Successfully");
                        }
                      }}
                    />
                    // <DeleteIcon
                    //   className="text-basic"
                    //   onClick={async () => {
                    //     const res = await deleteTransaction({
                    //       variables: {
                    //         id: transaction.id,
                    //       },
                    //     });

                    //     if (res.data.delete_inq_transection.returning?.[0]?.id) {
                    //       toast.success("Transaction Deleted Successfully");
                    //     }
                    //   }}
                    //   sx={{ color: "#9254F8", cursor: "pointer" }}
                    // />
                  )}
                </div>
              </div>
              <div className="self-stretch flex flex-col items-start justify-center text-right">
                <b className="relative leading-[130%] text-29xl">
                  £{transaction?.amount}
                </b>
              </div>
            </div>
            <div className="self-stretch flex flex-row items-center justify-between px-[25px] py-[20px] rounded-b-lg gap-[10px] text-base bg-basic text-white text-sm">
              <div className="relative flex items-start gap-1">
                <p className="m-0">Payment Date:</p>
                <p className="m-0">
                  <b>{moment(transaction?.created_at).format("DD-MM-YYYY")}</b>
                </p>

              </div>
              <div className="flex gap-5">
                {transaction.status === "paid" &&
                  inquiry?.inq_tickets?.[0]?.status !== "issued" && (
                    <p
                      onClick={() => setOpenRefMod(true)}
                      className="bg-black text-white font-bold px-2 rounded-2xl cursor-pointer"
                    >
                      Refund
                    </p>
                  )}
                <SideDrawer
                  setSidePopup={setOpenRefMod}
                  sidePopup={openRefMod}
                  component={
                    <RefundModal
                      setSidePopup={setOpenRefMod}
                      transaction={transaction}
                      inquiry={inquiry}
                    />
                  }
                />

                {!hideActions && (
                  <div className="relative text-center">
                    {transaction?.status === "pending" ||
                      transaction?.status === "request" ? (
                      <>
                        {transaction?.acc_payment_method?.type === "bank" ? (
                          <p className="m-0">
                            <p className="text-tomato bg-white px-2 rounded-2xl w-[170px] font-medium">Awaiting Confirmation</p>
                          </p>
                        ) : (
                          <>
                            {role === "consultant" ? (
                              <p className="m-0">
                                <p className="text-tomato bg-white px-2 rounded-2xl w-[170px] font-medium">Awaiting Payment</p>
                              </p>
                            ) : (
                              <a
                                className="bg-white text-[#2373c5] font-medium px-3 py-1 rounded-2xl animate-pulse"
                                href={transaction?.receipt}
                                target="_blank"
                              >
                                Pay
                              </a>
                            )}
                          </>
                        )}
                      </>
                    ) : transaction?.status === "paid" ? (
                      <>
                        <p className="m-0">
                          <p className="bg-white text-green-500 px-2 rounded-2xl">Paid</p>
                        </p>
                      </>
                    ) : transaction?.status === "refunded" ? (
                      <>
                        <p className="m-0 bg-white text-basic px-2 rounded-2xl">
                          <b>Refunded ({transaction.refund_amount})</b>
                        </p>
                      </>
                    ) : transaction?.status === "partiallyRefunded" ? (
                      <>
                        <p className="m-0 bg-white text-basic px-2 rounded-2xl">
                          <b>Partially Refunded ({transaction.refund_amount})</b>
                        </p>
                      </>
                    ) : transaction?.status === "adjusted" ? (
                      <>
                        <p className="m-0 bg-white text-basic px-2 rounded-2xl">
                          <b>Adjusted</b>
                        </p>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setEditForm(true);
                          }}
                          className="bg-[#2373c5] p-4 rounded-md text-white"
                        >
                          Pay
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {showReceipt && (
            <div className="fixed z-[999] top-0 left-0 w-full min-h-screen bg-[#00000070] flex justify-center items-center py-[100px]">
              <div className="container bg-white rounded-[10px] p-[30px] max-w-[900px] m-[20px] w-full relative">
                <button
                  onClick={() => setShowReceipt(false)}
                  className="text-[16px] text-white flex justify-center items-center h-[25px] w-[25px] bg-red-500 rounded-full absolute right-[-10px] top-[-10px]"
                >
                  x
                </button>
                <div className="">
                  {transaction?.receipt ? (
                    <img src={`${URLs.FILE_URL}${transaction?.receipt}`} />
                  ) : (
                    <p className="text-center">No file available</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="self-stretch relative flex-1 bg-white-100 flex flex-col p-[25px] items-start justify-between text-left text-mini text-darkslategray  border-[1px] border-solid border-lightsteelblue">
          <div className="self-stretch flex flex-col items-center justify-between">
            <button
              onClick={() => {
                setEditForm(false);
              }}
              className="absolute top-0 right-[5px] m-2"
            >
              <FaTimes />
            </button>
            <PaymentEdit
              inquiry={inquiry}
              setEditForm={setEditForm}
              transaction={transaction}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentCard;
