import React, { useState } from "react";
import User from "../../assets/img/User.svg";
import Phone from "../../assets/img/phone.png";
import Email from "../../assets/img/email.png";
import Edit from "../../assets/img/edit.png";
import Address from "../../assets/img/address.png";
import { Link } from "react-router-dom";
import { useUserDefaultRole } from "@nhost/react";
import { BiSolidMessageSquareEdit } from "react-icons/bi";
import { FaCircleUser } from "react-icons/fa6";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { nhost } from "~/lib/nhost";
import { URLs } from "~/config/enums";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_AVATAR } from "~/graphql/users/Mutation";

export default function UserBox({
  user,
  setEdit,
  editAble,
  pickedBy,
  inquiry,
  transactions,
}: any) {
  const [ProfileImageId, setProfileImageId] = useState<any>(user.avatarUrl);
  const userRole = useUserDefaultRole();

  const [updateUserAvatar] = useMutation(UPDATE_USER_AVATAR);

  const deleteFileFromNhot = async (fileId: any) => {
    try {
      const { error } = await nhost.storage.delete({ fileId });
      if (error) {
        console.error("Error deleting file:", error);
        return;
      }
      console.log("File deleted successfully");
    } catch (err) {
      console.error("Unexpected error during file deletion:", err);
    }
  };

  const uploadFiletoNhost = async (file: any) => {
    console.log("Uploading file:", file);
    try {
      const response: any = await nhost.storage.upload({ file });
      const fileIdD = response?.fileMetadata?.id;

      return fileIdD;
    } catch (err) {
      console.error("Unexpected error during file upload:", err);
    }
  };

  const onUploadImage = async (event: any) => {
    const file = event.target.files[0];

    if (ProfileImageId) {
      deleteFileFromNhot(ProfileImageId);
    }

    if (file) {
      const res = await uploadFiletoNhost(file);
      if (res) {
        setProfileImageId(res);
        await updateUserAvatar({
          variables: { userId: user.id, avatarUrl: res },
        });
      }
    }
  };

  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col items-end justify-between min-h-[275px]">
      <div className="self-stretch flex flex-col pt-0 pb-[9px] items-start justify-start gap-[14px] text-[20px] text-steelblue-100 px-8 my-5">
        {/* <img
          className="relative rounded-45xl overflow-hidden shrink-0 object-cover"
          alt=""
          src={User}
        /> */}
        <div className="flex items-center justify-center relative cursor-pointer group">
          <div className="flex items-center justify-center absolute z-30 bg-black/50 h-20 w-20 rounded-full opacity-0 group-hover:opacity-100">
            <input
              type="file"
              accept="image/*"
              className="opacity-0 absolute h-full w-full cursor-pointer"
              onChange={onUploadImage}
            />
            <AddAPhotoIcon fontSize="large" className="text-black" />
          </div>
          {!ProfileImageId ? (
            <FaCircleUser className=" h-20 w-20 text-basic dark:text-dark-primary relative opacity-100 group-hover:opacity-50" />
          ) : (
            <img
              className="h-20 w-20 relative rounded-full object-cover opacity-100 group-hover:opacity-50"
              src={URLs.FILE_URL + ProfileImageId}
            />
          )}
        </div>
        <div className="flex flex-col items-start justify-start gap-[9px]">
          <div className="flex flex-col items-start justify-start">
            <div className="relative leading-[20px] font-semibold capitalize text-basic dark:text-dark-primary cursor-pointer">
              <Link
                to={`/${userRole}/brands/${inquiry?.brd_id}/customer/${inquiry?.users?.id}`}
              >
                {`${user?.displayName || user?.name} (${
                  -transactions?.reduce((sum: any, transaction: any) => {
                    return transaction.type === "credit" &&
                      transaction?.def_acc_id === 4
                      ? sum + transaction.amount
                      : sum;
                  }, 0) -
                  transactions?.reduce((sum: any, transaction: any) => {
                    return transaction.type === "debit" &&
                      transaction?.def_acc_id === 4
                      ? sum + transaction.amount
                      : sum;
                  }, 0)
                })`}
              </Link>
            </div>
            {/* <div className="relative text-xs leading-[20px] inline-block  h-4 shrink-0 capitalize text-basic">
              {user?.thp_list?.length > 0
                ? "Super Admin"
                : user?.defaultRole || user?.role}
            </div> */}
          </div>
          <div className="flex flex-row items-center justify-start gap-[7px] text-center text-xs text-mediumseagreen">
            {!user?.disabled ? (
              <>
                <div className="relative rounded-[50%] bg-mediumseagreen w-2 h-2" />
                <div className="relative leading-[14px] capitalize">Active</div>
              </>
            ) : (
              <>
                <div className="relative rounded-[50%] bg-[red] w-2 h-2" />
                <div className="relative leading-[14px] text-[red] capitalize">
                  Suspended
                </div>
              </>
            )}
          </div>
        </div>
        {editAble && (
          <div className="flex items-center justify-start gap-[9px] absolute top-[5%] right-[2%]">
            <BiSolidMessageSquareEdit
              onClick={() => {
                setEdit(true);
              }}
              className="cursor-pointer text-basic dark:text-dark-primary relative w-5 h-5"
            />
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-col w-full bg-basic gap-2 rounded-b-2xl py-5 px-8">
        <div className="flex-1 pd-4 text-white">
          <div className="self-stretch flex flex-row items-start justify-start gap-[5px]">
            {/* <img
              className="relative w-[15.06px] h-[15px] overflow-hidden shrink-0"
              alt=""
              src={Phone}
            /> */}
            <div className="relative font-semibold text-base">
              {" "}
              {(user?.phoneNumber || user?.metadata?.phone || user?.phone) ??
                "N/A"}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              {/* <img
                className="relative w-[12.94px] h-[8.39px]"
                alt=""
                src={Email}
              /> */}
              <div className="relative font-semibold text-base">
                {" "}
                {user?.email || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 text-white">
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              {/* <img className="relative  h-[8.39px]" alt="" src={Address} /> */}
              <div className="relative font-semibold text-base">
                {" "}
                {user?.metadata?.country || "N/A"}
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-start gap-[7px]">
              {/* <img className="relative  h-[8.39px]" alt="" src={Address} /> */}
              <div className="relative font-semibold text-base">
                {" "}
                {user?.metadata?.addressLine1 || "N/A"}{" "}
                {user?.metadata?.addressLine2 || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
