import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  DateRangePicker,
  DatePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import TravelersPopover from "~/components/FormInputs/TravelersForm";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import LocationBox from "~/components/FormInputs/LocationBox/LocationBox";

type TravelerCategory = "children" | "infantsOnLap" | "infantsInSeat";

const today = dayjs();

interface FlightComponentProps {
  control: Control<any>;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors: FieldErrors<any>;
}

const FlightComponent: React.FC<FlightComponentProps> = ({
  control,
  register,
  setValue,
  errors,
  defaultValues,
}) => {
  const flightDetails = defaultValues
    ? defaultValues.find((item: any) => item.label === "Flights")?.details
    : [];
  const flightAdults = flightDetails?.inq_passengers
    ? flightDetails?.inq_passengers?.find((item: any) => item.type === "adult")
    : [];
  const flightChildren = flightDetails?.inq_passengers
    ? flightDetails?.inq_passengers?.find((item: any) => item.type === "child")
    : [];
  const flightInfants = flightDetails?.inq_passengers
    ? flightDetails?.inq_passengers?.find((item: any) => item.type === "infant")
    : [];

  const defaultTripType = useMemo(() => {
    const initialTripType = flightDetails?.[0]?.trip_type || "roundTrip";
    console.log("defaultTripType from useMemo:", initialTripType); // Debugging
    return initialTripType;
  }, [flightDetails]);

  // Step 2: Initialize tripType state using defaultTripType
  const [tripType, setTripType] = useState(defaultTripType);

  // Step 3: Update tripType whenever flightDetails change
  useEffect(() => {
    console.log("Updated flightDetails in useEffect:", flightDetails);
    if (flightDetails && flightDetails[0]?.trip_type) {
      setTripType(flightDetails[0].trip_type);
    }
  }, [flightDetails]);

  // Log the value of tripType for debugging
  useEffect(() => {
    console.log("tripType updated:", tripType); // Debugging tripType value
  }, [tripType]);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infantsOnLap, setInfantsOnLap] = useState(0);
  const [infantsInSeat, setInfantsInSeat] = useState(0);

  const [dateRange, setDateRange] = useState<[Dayjs | null, Dayjs | null]>([
    today,
    today.add(1, "day"),
  ]);

  // Initialize travelers state
  const [travelers, setTravelers] = useState<{
    [key in TravelerCategory]: string[];
  }>({
    children: Array(children).fill(""),
    infantsOnLap: Array(infantsOnLap).fill(""),
    infantsInSeat: Array(infantsInSeat).fill(""),
  });

  const handleTravelersClick = (event: any) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleTravelersClose = () => {
    setAnchorEl2(null);
  };

  useEffect(() => {
    setTravelers((prevState) => ({
      ...prevState,
      infantsOnLap: Array(infantsOnLap).fill(""),
      infantsInSeat: Array(infantsInSeat).fill(""),
    }));
  }, [infantsOnLap, infantsInSeat]);

  const handleAgeChange = (
    category: TravelerCategory,
    index: number,
    value: string
  ) => {
    console.log(
      `Changing age for category: ${category}, index: ${index}, value: ${value}`
    );

    setTravelers((prevState) => {
      const updatedArray = [...prevState[category]];
      updatedArray[index] = value; // Update only the specific index
      return { ...prevState, [category]: updatedArray };
    });

    setValue(`flights.Travelers.${category}.${index}`, value);
  };

  const updateTravelerCount = (
    type: "adults" | "children" | "infantsOnLap",
    action: "increment" | "decrement"
  ) => {
    let newCount;
    if (type === "adults") {
      newCount = action === "increment" ? adults + 1 : Math.max(adults - 1, 0);
      setAdults(newCount);
      setValue(`flights.Travelers.adults`, newCount);
    } else if (type === "children") {
      newCount =
        action === "increment" ? children + 1 : Math.max(children - 1, 0);
      setChildren(newCount);
      setValue(`flights.Travelers.children`, newCount);
    } else if (type === "infantsOnLap") {
      newCount =
        action === "increment"
          ? infantsOnLap + 1
          : Math.max(infantsOnLap - 1, 0);
      setInfantsOnLap(newCount);
      setValue(`flights.Travelers.infantsOnLap`, newCount);
    }

    // Update the travelers state
    setTravelers({
      children: Array(children).fill(""),
      infantsOnLap: Array(infantsOnLap).fill(""),
      infantsInSeat: Array(infantsInSeat).fill(""),
    });
  };

  const handleDateRangeChange = (newRange: [Dayjs | null, Dayjs | null]) => {
    setDateRange(newRange);
  };

  const handleTripTypeChange = (event: any) => {
    setTripType(event.target.value);
  };
  // useEffect(() => {
  //   if (flightDetails?.[0]?.trip_type) {
  //     setTripType(flightDetails[0].trip_type);
  //   }
  // }, [flightDetails]);
  return (
    <>
      <FormControl
        fullWidth
        variant="outlined"
        sx={{ minWidth: 120, marginBottom: 2 }}
      >
        <InputLabel id="trip-type-label">Trip Type</InputLabel>
        <Select
          {...register("flights.tripType", { required: true })}
          labelId="trip-type-label"
          value={tripType || ""}
          onChange={handleTripTypeChange}
          label="Trip Type"
        >
          <MenuItem value="roundTrip">Round-trip</MenuItem>
          <MenuItem value="oneWay">One-way</MenuItem>
        </Select>
      </FormControl>

      {tripType === "roundTrip" && (
        <Controller
          control={control}
          name={`flights.${tripType}.Dates`}
          rules={{
            validate: (value) =>
              value && value[0] && value[1] ? true : "This field is required", // Ensure both dates are selected
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const roundStart = dayjs(flightDetails?.[0]?.arrival_datetime);
            const roundEndDate = dayjs(flightDetails?.[0]?.departure_datetime);
            return (
              <>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    components={["SingleInputDateRangeField"]}
                    sx={{ margin: 0, padding: 0 }}
                  >
                    <DateRangePicker
                      value={[roundStart, roundEndDate] || value}
                      onChange={(newValue) => {
                        onChange(newValue); // Update the form value
                        handleDateRangeChange(
                          newValue as [Dayjs | null, Dayjs | null]
                        ); // Custom handler
                      }}
                      minDate={today}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span> // Extract the error message here
                )}
              </>
            );
          }}
        />
        // <Controller
        //   control={control}
        //   rules={{ required: true }}
        //   name={`flights.${tripType}.Dates`}
        //   render={({ field: { onChange, value } }) => (
        //     <LocalizationProvider dateAdapter={AdapterDayjs}>
        //       <DemoContainer components={["SingleInputDateRangeField"]}>
        //         <DateRangePicker
        //           value={value}
        //           onChange={(newValue) => {
        //             onChange(newValue);
        //             handleDateRangeChange(newValue);
        //           }}
        //           minDate={today}
        //           label="Select Dates"
        //         />
        //       </DemoContainer>
        //     </LocalizationProvider>
        //   )}
        // />
      )}

      {tripType === "oneWay" && (
        <Controller
          control={control}
          name={`flights.${tripType}.Dates`}
          rules={{
            validate: (value) => (value ? true : "This field is required"), // Only check if a single date is selected
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const OneWayDate = dayjs(flightDetails?.[0]?.departure_datetime);
            return (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={OneWayDate || value} // Ensure value is not undefined
                  onChange={(newValue) => {
                    onChange(newValue); // Update the value in react-hook-form
                  }}
                  minDate={today} // Ensure minDate is defined
                />
                {error && (
                  <span className="text-red-500 text-xs">{error.message}</span> // Render error if exists
                )}
              </LocalizationProvider>
            );
          }}
        />
      )}
      <Controller
        control={control}
        rules={{ required: "This is required" }}
        defaultValue={flightDetails?.[0]?.cabin_class}
        {...register("flights.cabinClass", { required: true })}
        render={({ field, fieldState: { error } }) => (
          <>
            <Autocomplete
              {...field}
              disablePortal
              onChange={(_, data: any) => {
                field.onChange(data);
              }}
              options={["Economy", "Business"]}
              getOptionLabel={(option: any) => option}
              renderOption={(props, option: any) => (
                <Box component="li" {...props}>
                  {option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  label="Cabin Class"
                  error={!!errors["cabinClass"]}
                  {...params}
                />
              )}
              noOptionsText=""
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Render error if exists
            )}
          </>
        )}
      />

      {/* Going to */}
      <Controller
        control={control}
        name="flights.GoingTo"
        rules={{ required: "Going to is required" }}
        render={({ field, fieldState: { error } }) => (
          <>
            <LocationBox
              {...field}
              control={control}
              errors={errors}
              register={register}
              fieldName="flights.GoingTo"
              label="Going to"
              onChange={(value: any) => {
                field.onChange(value);
                setValue("flights.GoingTo", value);
              }}
              defaultValue={flightDetails?.[0]?.to}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Render error if exists
            )}
          </>
        )}
      />

      {/* Leaving from */}
      <Controller
        control={control}
        name="flights.LeavingFrom"
        rules={{ required: "Leaving from is required" }}
        render={({ field, fieldState: { error } }) => (
          <>
            <LocationBox
              {...field}
              control={control}
              errors={errors}
              register={register}
              fieldName="flights.LeavingFrom"
              label="Leaving from"
              onChange={(value: any) => {
                field.onChange(value);
                setValue("flights.LeavingFrom", value);
              }}
              defaultValue={flightDetails?.[0]?.from}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Render error if exists
            )}
          </>
        )}
      />

      {/* Travelers Selection */}
      <Controller
        control={control}
        name={"flights.Travelers"}
        defaultValue={{ adults: 1, children: 0, infantsOnLap: 0 }}
        render={({ field }) => (
          <TravelersPopover
            {...field}
            open={Boolean(anchorEl2)}
            anchorEl={anchorEl2}
            handleClose={handleTravelersClose}
            handleClick={handleTravelersClick}
            adults={adults}
            setAdults={setAdults}
            setChildren={setChildren}
            setInfantsOnLap={setInfantsOnLap}
            children={children}
            infantsOnLap={infantsOnLap}
            travelers={travelers}
            handleAgeChange={handleAgeChange}
            ageOptions={Array.from({ length: 18 }, (_, i) => i.toString())}
            infantOptions={["0", "1"]}
            popoverWidth="400px"
            label="Travelers"
            updateTravelerCount={updateTravelerCount}
          />
        )}
      />
    </>
  );
};

export default FlightComponent;
