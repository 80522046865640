import React from "react";
import moment from "moment";
import AirlineLogoImage from "~/utils/airlineLogoImage";
import vector81 from "../../../images/dottedImg.svg";
import vector from "../../../images/vector.svg";
import vector1 from "../../../images/vector-1.svg";
// Helper functions (ensure these are defined or imported)
const timeDuration = (start: string, end: string) => {
  const diff = moment(end).diff(moment(start));
  const duration = moment.duration(diff);
  return { hours: duration.hours(), minutes: duration.minutes() };
};

const getAirportNameByIATA = (iata: string) => `Airport Name of ${iata}`; // Placeholder function
const getAirlineNameByIATA = (iata: string) => `Airline Name of ${iata}`; // Placeholder function
const getHoursAndMinutes = (start: string, end: string) => {
  const { hours, minutes } = timeDuration(start, end);
  return `${hours}h ${minutes}m`;
};

const FlightInvoiceSuggestion = ({ invoice }: any) => {
  console.log("fnsdmfbsdmnf", invoice);
  return (
    <div className="w-full flex flex-col items-start justify-start">
      {invoice?.invoice_suggestions
        ?.filter((suggestion: any) => suggestion.type === "flights") // Filter by type
        ?.[0]?.inq_suggestion?.inq_suggestion_legs?.map(
          (suggestion: any, ind: number) => (
            <div className="self-stretch rounded-xl flex flex-col items-start justify-start max-w-full">
              <div className="self-stretch bg-[#F9FAFC] flex flex-row w-full  items-center justify-between py-[5px] px-2.5 box-border [row-gap:20px]  max-w-full gap-[0px]">
                <b className="w-full relative inline-block max-w-full">
                  {suggestion?.type == "departing"
                    ? "Depart"
                    : suggestion?.type == "returning" && "Return"}
                </b>
                <div className="flex w-full flex-row items-center justify-end py-0 pr-0 pl-[193px] box-border gap-[5px]  max-w-full text-xs mq450:flex-wrap">
                  <div className="relative font-semibold inline-block min-w-[54px] capitalize">
                    {suggestion?.cabin}
                  </div>
                  <div className="flex flex-row items-start justify-start py-0 px-1 border-l-[1px] border-solid border-lightsteelblue">
                    <div className="relative font-light inline-block min-w-[102px]">
                      {(() => {
                        let totalHours = 0;
                        let totalMinutes = 0;

                        suggestion?.inq_suggestion_leg_stops?.map(
                          (legs: any, ind: any) => {
                            const duration = timeDuration(
                              legs?.depart_datetime,
                              legs?.arrival_datetime
                            );
                            totalHours += duration.hours;
                            totalMinutes += duration.minutes;
                            // Ensure minutes don't exceed 60
                            totalHours += Math.floor(totalMinutes / 60);
                            totalMinutes %= 60;
                          }
                        );

                        return `${totalHours} hours 
                          ${totalMinutes} minutes`;
                      })()}
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch bg-white flex flex-row flex-wrap items-center justify-center p-5 box-border gap-[15px] max-w-full text-5xl">
                <div className="flex-1 flex flex-col items-start justify-start gap-[40px] min-w-[442px] max-w-full mq675:min-w-full">
                  {suggestion?.inq_suggestion_leg_stops
                    ?.sort((a: any, b: any) => a.ordering - b.ordering)
                    ?.map((legs: any, ind: any) => {
                      return (
                        <>
                          <div className="self-stretch flex flex-row items-center justify-start [row-gap:20px] mq675:flex-wrap">
                            <div className="flex flex-col items-start justify-center gap-[10px]">
                              <div className="flex flex-row items-end justify-start py-0 pr-[37px] pl-0">
                                <div className="relative font-medium inline-block shrink-0 mq450:text-lgi text-[24px]">
                                  {legs?.depart}
                                </div>
                                <h3 className="m-0 relative text-sm font-normal font-inherit inline-block min-w-[75px]">
                                  {`(${getAirportNameByIATA(legs?.depart)})`}
                                </h3>
                              </div>
                              <div className="relative text-xs text-gray-600 whitespace-nowrap">
                                {moment(legs?.depart_datetime)
                                  .zone(0)
                                  .format("DD-MM-YYYY")}{" "}
                                |{" "}
                                {moment(legs?.depart_datetime)
                                  .zone(0)
                                  .format("HH:mm")}
                              </div>
                            </div>
                            <img
                              className="h-px flex-1 relative max-w-full w-full overflow-hidden"
                              alt="dotted line"
                              src={vector81}
                            />
                            <div className="flex flex-col items-center justify-center gap-[6px] text-center text-xs text-gray-600">
                              <div className=" relative inline-block min-w-[108px]">
                                {getHoursAndMinutes(
                                  legs?.depart_datetime,
                                  legs?.arrival_datetime
                                )}
                              </div>
                              <div className="flex flex-row items-center justify-center gap-[6px] text-left text-gray-900">
                                <AirlineLogoImage
                                  src={legs?.airline_iata}
                                  alt="Airline Logo"
                                  className="h-5 w-5 relative object-cover"
                                />
                                <div className="relative inline-block min-w-[108px]">
                                  {getAirlineNameByIATA(legs?.airline_iata)}{" "}
                                  {`(${legs?.airline_iata})`}
                                </div>
                              </div>
                            </div>
                            <img
                              className="h-px flex-1 relative max-w-full w-full overflow-hidden "
                              alt="dotted line"
                              src={vector81}
                            />
                            <div className="flex flex-col items-end justify-start">
                              <div className="flex flex-col items-end justify-center gap-[10px]">
                                <div className="flex flex-row items-end justify-end py-0 pr-0 pl-[37px]">
                                  <h1 className="m-0 relative text-inherit font-medium font-inherit inline-block  mq450:text-lgi text-[24px]">
                                    {legs?.arrival}
                                  </h1>
                                  <h3 className="m-0 relative text-sm font-normal font-inherit inline-block min-w-[63px]">
                                    {`(${getAirportNameByIATA(legs?.arrival)})`}
                                  </h3>
                                </div>
                                <div className="relative text-xs text-gray-600 text-right">
                                  {moment(legs?.arrival_datetime)
                                    .zone(0)
                                    .format("DD-MM-YYYY")}{" "}
                                  |{" "}
                                  {moment(legs?.arrival_datetime)
                                    .zone(0)
                                    .format("HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {suggestion?.inq_suggestion_leg_stops?.[ind + 1] && (
                            <div className="self-stretch relative text-sm font-medium text-center">
                              <p className="m-0">
                                {getHoursAndMinutes(
                                  legs?.arrival_datetime,
                                  suggestion?.inq_suggestion_leg_stops?.[
                                    ind + 1
                                  ]?.depart_datetime
                                )}{" "}
                                (Stay)
                              </p>
                            </div>
                          )}
                        </>
                      );
                    })}

                  <div className="self-stretch flex flex-row items-center justify-start text-sm">
                    <div className="w-[284px] flex flex-row items-center justify-center gap-[41px]">
                      <div className="flex-1 flex flex-row items-center justify-start gap-[10px]">
                        <img
                          className="h-[19.8px] w-[25px] relative"
                          alt=""
                          src={vector}
                        />
                        <div className="flex-1 relative">
                          {suggestion?.luggage} Kg
                        </div>
                      </div>
                      <div className="w-[58px] flex flex-row items-center justify-start gap-[6px]">
                        <img
                          className="h-[25px] w-[13px] relative"
                          alt=""
                          src={vector1}
                        />
                        <div className="flex-1 relative">
                          {suggestion?.hand_carry} Kg
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="flex flex-col items-center justify-start h-full">
                <div className="w-3.5 rounded-t-3xs rounded-b-none bg-white box-border flex flex-col items-center justify-center py-[5px] px-[3px] border-[1px] border-solid border-lightslategray">
                  <img
                    className="w-1.5 h-[3px] relative"
                    alt=""
                    src={vector720}
                  />
                </div>
                <img
                  className="w-px flex-1 relative h-full max-h-full"
                  alt=""
                  src={vector721}
                />
              </div> */}
              </div>
            </div>
          )
        )}
      <div
        className="flex flex-col items-end justify-end gap-2 w-full"
        style={{ flex: 1 }}
      >
        {invoice?.invoice_suggestions
          ?.filter((suggestion: any) => suggestion.type === "flights") // Filter correctly
          ?.map((suggestion: any) => {
            // Calculate total sale price for each flight suggestion
            const totalSalePrice =
              suggestion?.inq_suggestion?.inq_suggestion_costs?.reduce(
                (total: number, cost: any) =>
                  total + parseFloat(cost?.sale_price || 0),
                0
              );

            return (
              <>
                {/* Display total sale price */}
                <div className="flex flex-col items-end text-sm gap-1 w-full">
                  <div className="flex flex-row items-center justify-end w-full mr-5">
                    <div className="font-medium">Total Sale Price</div>
                    <b className="ml-2">£ {totalSalePrice.toFixed(2)}</b>
                  </div>
                </div>
              </>
            );
          })}
      </div>

      {/* <div
        className="flex flex-col items-end justify-end gap-2 w-full "
        style={{ flex: 1 }}
      >
        {invoice?.invoice_suggestions
          ?.filter((suggestion: any) => suggestion.type === "flights") // Filter correctly
          ?.map((suggestion: any) =>
            suggestion?.inq_suggestion?.inq_suggestion_costs?.map(
              (cost: any, idx: number) => (
                <div
                  key={idx}
                  className="flex flex-col items-end text-sm gap-1 w-full" // Ensure full width
                >
                  <div className="flex flex-row items-center justify-end w-full mr-5">
                    {" "}
                   
                    <div className="font-medium">Sale Price</div>{" "}
                    
                    <b className="ml-2">£ {cost?.sale_price}</b>{" "}
                   
                  </div>
                </div>
              )
            )
          )}
      </div> */}
    </div>
  );
};

export default FlightInvoiceSuggestion;
