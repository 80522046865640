import React from "react";
import { getCityName } from "~/utils/utilsFunctions";

const TrainCard = ({
  origin,
  destination,
  date,
  adults,
  children,
  infants,
}: any) => {
  return (
    <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col min-h-[330px]">
      {/* Top section (40%) - Purple section */}
      <div className="self-stretch flex flex-col pb-2.5 pt-7 px-[30px] mt-5 flex-grow">
        {/* Origin and Destination in a Row */}
        <div className="flex flex-row justify-between items-center w-full">
          {/* Origin */}
          <div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
              {/* {getCityName(inquiry?.train_inq_details?.origin)} */}
              ORIGIN
            </div>
            <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
              {origin}
            </div>
          </div>

          {/* Destination */}
          <div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
              {/* {getCityName(inquiry?.train_inq_details?.destination)} */}
              DESTINATION
            </div>
            <div className="relative text-3xl font-medium text-basic dark:text-dark-primary text-right">
              {destination}
            </div>
          </div>
        </div>

        {/* Date positioned below the row, aligned to the left */}
        <div className="flex flex-row justify-start mt-4">
          <div className="relative font-medium text-basic dark:text-dark-primary text-base">
            {date}
          </div>
        </div>
      </div>

      {/* Bottom section (60%) - White section */}
      <div className="flex flex-col w-full bg-basic gap-2 rounded-b-2xl py-7 px-8 flex-grow">
        <div className="flex-1 text-white">
          <div className="self-stretch flex flex-row items-start justify-between">
            <div className="relative font-semibold text-base">
              Adults: {adults}
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between gap-x-3">
            <div className="relative font-semibold text-base">
              Children: {children}
            </div>
            <div className="relative font-semibold text-base">
              Infants: {infants}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainCard;
