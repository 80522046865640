import React, { Children, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import moment from "moment";
import { toast } from "react-toastify";
import useAssigedBrands from "~/hooks/useAssigedBrands";
import useAssignedUsers from "~/hooks/useAssignedUsers";
import useBrandPaymentMethod from "~/hooks/useBrandPaymentMethod";
import travelHouseStore from "~/store/travelHouse/travelHouseStore";
import { INSERT_INQUIRY } from "../graphql/Mutation";
import { generateRandomNumberString } from "~/utils/generateRandomId";
import NewInquiryTypes from "../NewInquiryTypes/index.tsx";
import InquirySelector from "../InquirySelector/index.tsx";
import { Field } from "../types/index.ts";
import { options } from "../data/options.ts";

const NewInquiryAdd = ({ setSidePopup, defaultTabs }: any) => {
  // const tabs = defaultTabs.map((tab: any, index: any) => tab?.label);
  const tabs = defaultTabs ? defaultTabs.map((tab: any) => tab.label) : [];
  console.log("lkjdlkasjdklas", defaultTabs, tabs);
  const { brands }: any = useAssigedBrands();
  const [formValue, setFormValue] = useState({
    brand: "",
    user: "",
    paymentMethod: "",
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm();

  const tripType = watch("tripType");

  const foundBrand =
    brands && brands.find((brand: any) => brand.name === formValue.brand);
  const brd_id = foundBrand ? foundBrand.id : null;

  const { users }: any = useAssignedUsers(brd_id);

  const foundUser =
    users && users.find((user: any) => user.displayName === formValue.user);
  const uid = foundUser ? foundUser.id : null;

  const { paymentMethod }: any = useBrandPaymentMethod(brd_id);

  const foundPaymentMethod =
    paymentMethod &&
    paymentMethod.find(
      (paymentMethod: any) => paymentMethod.name === formValue.paymentMethod
    );
  const pid = foundPaymentMethod ? foundPaymentMethod.id : null;
  const [selectedOptions, setSelectedOptions] = useState<string[]>(tabs);
  const [insertInquiry] = useMutation(INSERT_INQUIRY);
  const client = useApolloClient();
  const { travelHouse }: any = travelHouseStore();
  // const onSubmit = (data: any) => {
  //   const submissionData = {
  //     ...data,
  //   };

  //   console.log("Submission data:", submissionData);
  //   // Here you can proceed with the mutation or any further logic
  // };

  const onSubmit = async (data: any) => {
    console.log("Submitted data:", data);
    try {
      // Base inquiry data
      const inquiryData: any = {
        approx_budget: data?.approxBudget,
        payment_method_id: pid,
        flag_id: 64,
        status: "pending",
        brd_id,
        inquiry_no: generateRandomNumberString(),
        thp_id: travelHouse?.id,
        user_id: uid,
      };

      // Add stay details if 'stay' option is selected
      if (data?.stay) {
        const rooms = data?.stay?.Travelers?.length || 0; // Get the count of rooms
        // Initialize totals for adults and children
        let totalAdults = 0;
        let totalChildren = 0;

        // Calculate totals by iterating over the Travelers array
        if (data.stay.Travelers) {
          data.stay.Travelers.forEach((traveler: any) => {
            totalAdults += traveler.adults; // Sum adults
            totalChildren += traveler.children; // Sum children
          });
        }
        inquiryData.stay_inq_details = {
          data: {
            location: data?.stay?.whereTo,
            start_date: moment(data?.stay?.dates?.[0]).format("YYYY-MM-DD"),
            end_date: moment(data?.stay?.dates?.[1]).format("YYYY-MM-DD"),
            rooms: rooms,
            adults: totalAdults,
            children: totalChildren,
            stay_inq_rooms: {
              data:
                rooms > 0
                  ? [
                      ...Array(data?.stay?.Travelers.length || 0)
                        .fill(null)
                        .map((_, index) => ({
                          stay_inq_id: inquiryData?.stay_inq_details?.id,
                          booked_by: index === 0 ? data?.user : null, // Set name for the first adult
                        })),

                      // ...Array(data?.stay?.Travelers.length || 0)
                      //   .fill(null)
                      //   .map(() => ({
                      //     stay_inq_id: inquiryData?.stay_inq_details?.id,
                      //     booked_by: data?.user,
                      //   })),
                    ]
                  : [],
            },
          },
        };
      }

      // Add car details if 'cars' option is selected
      if (data?.cars) {
        inquiryData.car_inq_details = {
          data: {
            origin: data?.cars?.pickUp,
            destination: data?.cars?.DropOff,
            pick_date_time: moment(data?.cars?.pickupDateTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            drop_date_time: moment(data?.cars?.dropOffDateTime).format(
              "YYYY-MM-DD HH:mm"
            ),
            with_driver: data?.cars?.withDriver,
          },
        };
      }

      // Add train details if 'train' option is selected
      if (data?.train) {
        const travelers = data?.train?.Travelers || {}; // Ensure Travelers is defined

        inquiryData.train_inq_details = {
          data: {
            origin: data?.train?.Origin,
            destination: data?.train?.Destination,
            date: moment(data?.train?.Date).format("YYYY-MM-DD"),
            train_inq_passengers: {
              data: [
                ...Array(travelers.adults || 0)
                  .fill(null)
                  .map((_, index) => ({
                    type: "adult",
                    first_name: index === 0 ? data?.user : null, // Set name for the first adult
                  })),

                ...Array(travelers.children || 0)
                  .fill(null)
                  .map(() => ({
                    type: "child",
                  })),

                ...Array(travelers.infantsOnLap || 0)
                  .fill(null)
                  .map(() => ({
                    type: "infant",
                  })),
              ],
            },
          },
        };
      }

      // Add flight details if 'flights' option is selected
      if (data?.flights) {
        inquiryData.trip_type = data?.flights?.tripType;
        inquiryData.to = data?.flights?.GoingTo;
        inquiryData.from = data?.flights?.LeavingFrom;
        inquiryData.cabin_class = data?.flights?.cabinClass;
        inquiryData.departure_datetime =
          data?.flights?.RoundTrip?.Dates?.[0] || data?.flights?.OneWay?.Dates;
        inquiryData.arrival_datetime = data?.flights?.RoundTrip?.Dates?.[1];
        inquiryData.inq_passengers = {
          data: [
            // Mapping adults
            ...Array(data?.flights?.Travelers?.adults || 0)
              .fill(null)
              .map((_, index) => ({
                type: "adult",
                first_name: index === 0 ? data?.user : null, // Set name for the first adult
              })),

            // ...Array(data?.flights?.Travelers?.adults || 0)
            //   .fill(null)
            //   .map(() => ({
            //     type: "adult",
            //   })),
            // Mapping children
            ...Array(data?.flights?.Travelers?.children || 0)
              .fill(null)
              .map(() => ({
                type: "child",
              })),
            // Mapping infants
            ...Array(data?.flights?.Travelers?.infants || 0)
              .fill(null)
              .map(() => ({
                type: "infant",
              })),
          ],
        };
      }

      // Insert the inquiry with the constructed data
      const res = await insertInquiry({
        variables: {
          data: inquiryData,
        },
      });

      // Success response
      if (res?.data?.insert_inq_list_one?.id) {
        toast.success("Inquiry inserted successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        await client.refetchQueries({
          include: "all",
        });
        setSidePopup(false); // Close the side popup on success
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("Error inserting inquiry", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;
    setSelectedOptions((prev) =>
      checked ? [...prev, value] : prev.filter((option) => option !== value)
    );
    if (!checked) {
      reset({ [value]: undefined }); // Clear the data for unselected options
    }
  };

  const [fields, setFields] = useState<{ [key: string]: Field[] }>({});

  // Define options for the InquirySelector component
  const handleNewInquiryTypesSubmit = (inquiryTypes: any) => {
    setSelectedOptions(inquiryTypes);
  };
  console.log("sadnaksmda", errors, selectedOptions);
  return (
    <div className="md:w-[600px] w-[100vw] h-full overflow-y-scroll p-5 pt-10 bg-primary dark:bg-dark-primary">
      <h1 className="text-center text-3xl text-basic">Add Inquiry11</h1>
      <Divider />

      {/* Inquiry Selector */}
      <Box sx={{ mb: 3 }}>
        <InquirySelector
          options={options}
          selectedOptions={selectedOptions}
          onOptionChange={handleOptionChange}
        />
      </Box>

      {/* Form Fields */}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-5 mt-10">
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("brand", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.name);
                  setFormValue({
                    ...formValue,
                    brand: data?.name,
                  });
                }}
                options={brands ? brands : []}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Brand"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                      el && errors["brand"] && el.focus();
                    }}
                    error={errors["brand"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />

          {/* test */}

          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("user", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.displayName);
                  setFormValue({
                    ...formValue,
                    user: data?.displayName,
                  });
                }}
                options={users ? users : []}
                getOptionLabel={(option: any) => option?.displayName}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.displayName}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="User"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["user"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
          <TextField
            error={errors["approxBudget"] ? true : false}
            {...register("approxBudget", { required: true })}
            label="Approx Budget"
            type="number"
          />
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            {...register("paymentMethod", { required: true })}
            render={({ field }: any) => (
              <Autocomplete
                // sx={{ marginTop: "7px" }}
                {...field}
                disablePortal
                ref={field.ref}
                onChange={(_, data: any) => {
                  field.onChange(data?.name);
                  setFormValue({
                    ...formValue,
                    paymentMethod: data?.displayName,
                  });
                }}
                options={paymentMethod ? paymentMethod : []}
                getOptionLabel={(option: any) => option?.name}
                renderOption={(props, option: any) => (
                  <Box component="li" {...props}>
                    {option?.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    label="Payment Method"
                    ref={field.ref}
                    inputRef={(el) => {
                      field.ref(el);
                    }}
                    error={errors["paymentMethod"] ? true : false}
                    {...params}
                  />
                )}
                noOptionsText=""
              />
            )}
          />
        </div>

        {/* New Inquiry Types */}

        <NewInquiryTypes
          register={register}
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          watch={watch}
          setValue={setValue}
          selectedOptions={selectedOptions}
          onSubmit={handleNewInquiryTypesSubmit}
          defaultValues={defaultTabs}
        />

        <Button
          type="submit"
          sx={{ width: "100%", marginTop: "25px" }}
          variant="contained"
        >
          Add
        </Button>
      </Box>
    </div>
  );
};

export default NewInquiryAdd;
