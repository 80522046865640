import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useState } from "react";

import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import CityBox from "~/components/FormInputs/CityBox/CityBox";
import RoomForm from "~/components/FormInputs/RoomForm";

const today = dayjs();

const StayComponent = ({
  control,
  register,
  setValue,
  errors,
  defaultValues,
}: any) => {
  const stayDetails = defaultValues
    ? defaultValues.find((item: any) => item.label === "Stay")?.details
    : [];
  console.log("asdnbasmndbas", defaultValues, stayDetails);
  const handleDateRangeChange = (newValue: [Dayjs | null, Dayjs | null]) => {
    console.log("Selected date range: ", newValue); // Custom logic
  };

  const [selectedCityName, setSelectedCityName] = useState("");
  return (
    <>
      {/* Where To Field */}
      <Controller
        control={control}
        name="stay.whereTo"
        rules={{ required: "This is required" }}
        defaultValue={stayDetails?.[0]?.location}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <CityBox
              value={value} // Ensure value is initialized correctly
              onChange={onChange} // Make sure onChange is directly connected to form state
              setValue={setValue} // Optional, if additional logic is needed
              fieldName="stay.whereTo"
              label="Where to"
              errors={errors}
              setSelectedCityName={setSelectedCityName}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Extract the error message here
            )}
          </>
        )}
      />
      <Controller
        control={control}
        name="stay.dates"
        rules={{
          validate: (value) =>
            value && value[0] && value[1] ? true : "This field is required", // Ensure both dates are selected
        }}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const startDate = dayjs(stayDetails?.[0]?.start_date);
          const endDate = dayjs(stayDetails?.[0]?.end_date);
          return (
            <>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer
                  components={["SingleInputDateRangeField"]}
                  sx={{ margin: 0, padding: 0 }}
                >
                  <DateRangePicker
                    value={[startDate, endDate] || value}
                    onChange={(newValue) => {
                      onChange(newValue); // Update the form value
                      handleDateRangeChange(
                        newValue as [Dayjs | null, Dayjs | null]
                      ); // Custom handler
                    }}
                    minDate={today}
                  />
                </DemoContainer>
              </LocalizationProvider>
              {error && (
                <span className="text-red-500 text-xs">{error.message}</span> // Extract the error message here
              )}
            </>
          );
        }}
      />
      {/* Dates Field */}
      {/* <Controller
        control={control}
        rules={{ required: true }}
        name="stay.dates"
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const defaultValue: [Dayjs | null, Dayjs | null] = value || [
            today,
            today,
          ]; // Set to min date as both start and end
          return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["SingleInputDateRangeField"]}>
                <DateRangePicker
                  value={defaultValue} // Controlled value from form state
                  onChange={(newValue) => {
                    onChange(newValue); // Update the form value
                    handleDateRangeChange(
                      newValue as [Dayjs | null, Dayjs | null]
                    ); // Custom handler
                  }}
                  minDate={today}
                />
              </DemoContainer>
            </LocalizationProvider>
          );
        }}
      /> */}
      {/* Travelers */}

      <Controller
        control={control}
        name="stay.Travelers"
        defaultValue={
          stayDetails?.[0]
            ? [
                {
                  adults: stayDetails[0].adults,
                  children: stayDetails[0].children,
                },
              ]
            : [{ adults: 1, children: 0 }]
        }
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <RoomForm
            value={value || [{ adults: 1, children: 0 }]} // Handle fallback
            onChange={onChange}
            setValue={setValue}
            fieldName="Travelers"
            label="Travelers"
            errors={error}
          />
        )}
      />
    </>
  );
};

export default StayComponent;
