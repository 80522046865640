import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import dayjs from "dayjs";

import CityBox from "~/components/FormInputs/CityBox/CityBox";

const today = dayjs();

const CarsComponent = ({
  control,
  register,
  setValue,
  errors,
  defaultValues,
}: any) => {
  const carDetails = defaultValues
    ? defaultValues.find((item: any) => item.label === "Cars")?.details
    : [];
  return (
    <>
      {/* Pick-Up Location */}

      <Controller
        control={control}
        name="cars.pickUp"
        rules={{ required: "This is required" }}
        defaultValue={carDetails?.[0]?.origin}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <CityBox
              value={value} // Ensure value is initialized correctly
              onChange={onChange} // Make sure onChange is directly connected to form state
              setValue={setValue} // Optional, if additional logic is needed
              fieldName="pickUp"
              label="Pick up"
              errors={errors}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Extract the error message here
            )}
          </>
        )}
      />

      {/* Drop-Off Location */}

      <Controller
        control={control}
        name="cars.DropOff"
        rules={{ required: "This is required" }}
        defaultValue={carDetails?.[0]?.destination}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <>
            <CityBox
              value={value} // Ensure value is initialized correctly
              onChange={onChange} // Make sure onChange is directly connected to form state
              setValue={setValue} // Optional, if additional logic is needed
              fieldName="cars.DropOff"
              label="Drop Off"
              errors={errors}
            />
            {error && (
              <span className="text-red-500 text-xs">{error.message}</span> // Extract the error message here
            )}
          </>
        )}
      />

      {/* Pick-Up Date & Time */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <Controller
            control={control}
            name="cars.pickupDateTime"
            rules={{ required: "Pick-up date and time is required" }}
            render={({ field, fieldState: { error } }) => {
              const pickupDateTime = dayjs(carDetails?.[0]?.pick_date_time);

              return (
                <>
                  <DateTimePicker
                    value={pickupDateTime || field.value}
                    onChange={(date) => field.onChange(date)}
                    sx={{ width: "100%" }}
                    label="Pick-Up Date & Time"
                    // minDateTime={today}
                    slotProps={{
                      textField: {
                        error: !!errors["cars.pickupDateTime"],
                      },
                    }}
                  />
                  {error && (
                    <span className="text-red-500 text-xs">
                      {error.message}
                    </span> // Extract the error message here
                  )}
                </>
              );
            }}
          />
        </DemoContainer>
      </LocalizationProvider>

      {/* Drop-Off Date & Time */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DateTimePicker"]}>
          <Controller
            control={control}
            name="cars.dropOffDateTime"
            rules={{ required: "Drop-off date and time is required" }}
            render={({ field, fieldState: { error } }) => {
              const dropOffDateTime = dayjs(carDetails?.[0]?.drop_date_time);
              return (
                <>
                  <DateTimePicker
                    value={dropOffDateTime || field.value}
                    onChange={(date) => field.onChange(date)}
                    // minDateTime={today}
                    sx={{ width: "100%" }}
                    label="Drop-Off Date & Time"
                    slotProps={{
                      textField: {
                        error: !!errors["cars.dropOffDateTime"],
                      },
                    }}
                  />
                  {error && (
                    <span className="text-red-500 text-xs">
                      {error.message}
                    </span> // Extract the error message here
                  )}
                </>
              );
            }}
          />
        </DemoContainer>
      </LocalizationProvider>

      {/* With Driver Checkbox */}
      <FormGroup className="ml-2">
        <FormControlLabel
          control={
            <Checkbox
              {...register("cars.withDriver")}
              color="primary"
              defaultChecked={carDetails?.[0]?.with_driver || false}
            />
          }
          label="With Driver"
        />
      </FormGroup>
    </>
  );
};

export default CarsComponent;
