import React, { useEffect, useState } from "react";
import { FormControl, Box, Typography } from "@mui/material";
import { styled } from "@mui/system";

import {
  Control,
  UseFormRegister,
  FieldValues,
  SubmitHandler,
  Controller,
} from "react-hook-form";

import { options } from "../data/options.ts";
import StayComponent from "../components/Stay/index.tsx";
import CarsComponent from "../components/cars/index.tsx";
import TrainComponent from "../components/train/index.tsx";
import FlightComponent from "../components/flight/index.tsx";

const SectionContainer = styled(Box)({
  border: "1px solid #ccc",
  borderRadius: "8px",
  padding: "20px",
  marginTop: "20px",
});

const ListContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  listStyle: "none",
});

const NewInquiryTypes = ({
  register,
  control,
  handleSubmit,
  errors,
  watch,
  setValue,
  selectedOptions,
  onSubmit,
  defaultValues,
}: any) => {
  const onSubmitForm = (data: any) => {
    const submissionData = {
      ...data,
    };

    console.log("Submission data:", submissionData);
    // Here you can proceed with the mutation or any further logic
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <FormControl component="fieldset" fullWidth sx={{ mt: 1 }}>
        {selectedOptions.map((optionName: any) => {
          // Get the fields for the current option
          const option = options.find((opt) => opt.name === optionName);
          const fieldsForOption = option ? option.fields : [];

          return (
            <>
              <SectionContainer key={optionName}>
                <Typography variant="h6" gutterBottom>
                  {optionName} Details
                </Typography>
                <ListContainer component="ul">
                  {optionName === "Hotel" ? (
                    <>
                      <StayComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        defaultValues={defaultValues}
                      />
                    </>
                  ) : optionName === "Cars" ? (
                    <>
                      <CarsComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        defaultValues={defaultValues}
                      />
                    </>
                  ) : optionName === "Train" ? (
                    <>
                      <TrainComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        defaultValues={defaultValues}
                      />
                    </>
                  ) : optionName === "Flights" ? (
                    <>
                      <FlightComponent
                        control={control}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                        defaultValues={defaultValues}
                      />
                    </>
                  ) : null}
                </ListContainer>
              </SectionContainer>
            </>
          );
        })}
      </FormControl>
    </form>
  );
};

export default NewInquiryTypes;
