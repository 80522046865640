import React from "react";
import { Box, Typography } from "@mui/material";

import { useUserDefaultRole, useUserId } from "@nhost/react";
import StayCard from "~/modules/InquiriesModule/components/StayCard";
import CarCard from "~/modules/InquiriesModule/components/CarCard";
import TrainCard from "~/modules/InquiriesModule/components/TrainCard";
import InquiryCard from "~/modules/InquiriesModule/components/InquiryCard";

const TabPanel = ({ children, value, index, ...other }: any) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className="h-full" // Ensure it takes full height
    >
      {value === index && (
        <Box sx={{ p: 0, height: "100%" }}>
          {" "}
          {/* Remove padding and set height */}
          {children}
        </Box>
      )}
    </div>
  );
};

interface InquiryTabPanelProps {
  value: number;
  tabs: { label: string; details: any[] }[];
  inquiry: any;
  className: any;
}

const InquiryTabPanel: React.FC<InquiryTabPanelProps> = ({
  value,
  tabs,
  inquiry,
  className,
}) => {
  const user_id = useUserId();
  const defaultRole = useUserDefaultRole();
  console.log("asndban", tabs);
  return (
    <>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {!tab.details || tab.details.length === 0 ? (
            <Typography>No details available</Typography>
          ) : (
            <>
              {tab.details.map((detail: any, detailIndex: any) => (
                <>
                  {console.log("asndmasndas", detail)}
                  <div className="relative shadow bg-secondary dark:bg-dark-secondary rounded-2xl flex flex-col items-center justify-end text-left text-xs text-darkslategray">
                    {tab.label === "Stay" && (
                      <StayCard
                        location={detail.location}
                        startDate={detail.start_date}
                        endDate={detail.end_date}
                        rooms={detail.rooms}
                        detail={detail}
                        height="300px"
                        adults={detail.adults}
                        children={detail.children}
                        // className="flex-1 min-h-[300px] rounded-2xl bg-white dark:bg-dark-secondary shadow border-none"
                      />
                    )}
                    {tab.label === "Cars" && (
                      <CarCard
                        origin={detail.origin}
                        destination={detail.destination}
                        Pick_Date_Time={detail.pick_date_time}
                        Drop_Date_Time={detail.drop_date_time}
                        withDriver={detail.with_driver ? "Yes" : "No"}
                        height="300px"
                      />
                    )}
                    {tab.label === "Train" && (
                      <TrainCard
                        origin={detail.origin}
                        destination={detail.destination}
                        date={detail.date}
                        adults={detail.adults}
                        children={detail.children}
                        infants={detail.infants}
                        height="300px"
                      />
                    )}
                    {tab.label === "Flights" && (
                      <InquiryCard
                        inquiry={inquiry}
                        header={false}
                        height="300px"
                        editAble={
                          inquiry.status !== "completed" &&
                          (user_id === inquiry.picked_by ||
                            defaultRole === "admin")
                        }
                      />
                    )}
                  </div>
                </>
              ))}
            </>
          )}
        </TabPanel>
      ))}
    </>
  );
};

export default InquiryTabPanel;
