import React from "react";

import { splitLocation } from "~/utils/splitLocation";
import moment from "moment-timezone";
const CarCard = ({
  origin,
  destination,
  Pick_Date_Time,
  Drop_Date_Time,
  withDriver,
  height,
}: any) => {
  // Split origin into city and remaining address
  const { cityName: originCity, remainingAddress: originAddress } =
    splitLocation(origin);

  // Split destination into city and remaining address
  const { cityName: destinationCity, remainingAddress: destinationAddress } =
    splitLocation(destination);

  return (
    <div
      className={`flex-1 w-full rounded-2xl shadow bg-secondary dark:bg-dark-secondary text-primary dark:to-dark-primary shadow min-h-[${height}] flex flex-col pt-0 px-0 box-border items-end justify-between text-left text-xs
  `}
    >
      <div className="w-full text-primary dark:text-dark-primary flex flex-col gap-3.5 justify-between rounded-2xl h-full">
        <div className="self-stretch flex flex-row pb-2.5 pt-8 px-[30px]  items-center justify-between">
          <div className="flex flex-col items-start justify-start gap-[1px]">
            <div className="self-stretch relative text-3xl font-medium text-basic dark:text-dark-primary">
              {originCity}
            </div>
            <div className="self-stretch relative font-medium text-basic dark:text-dark-primary">
              {originAddress}
            </div>
          </div>
          <div className="w-full">{/* <DistinationLine /> */}</div>
          <div className="flex flex-col items-end justify-start text-right">
            <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
              {destinationCity}
            </div>
            <div className="self-stretch relative text-basic dark:text-dark-primary font-medium">
              {destinationAddress}
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-1 md:flex-row px-[30px] py-10 items-center justify-between bg-basic rounded-b-2xl">
        <div className="flex flex-col items-start justify-center w-full">
          <div className="flex flex-col items-start justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-base">
              {" "}
              {moment(Pick_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
            </div>

            <div className="flex flex-row items-center justify-start gap-1 mt-2">
              {/* <Money /> */}
              <div className="relative inline-block text-base shrink-0">
                <span>With Driver: </span>
                <b> {withDriver}</b>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-center w-full">
          <div className="flex flex-col items-end justify-start gap-1 text-white font-medium">
            <div className="self-stretch relative text-end text-base">
              {moment(Drop_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
            </div>
          </div>
        </div>
      </div>
    </div>

    // <div className="bg-secondary dark:bg-dark-secondary text-primary rounded-2xl dark:text-dark-primary self-stretch flex flex-col items-end justify-between min-h-[330px]">
    //   <div className="self-stretch flex  pb-2.5 pt-10 px-[30px] mt-5 items-center justify-between ">
    //     <div className="flex flex-col items-start justify-start gap-[1px]">
    //       <div className="self-stretch relative text-3xl font-medium text-basic dark:text-dark-primary">
    //         {originCity}
    //       </div>

    //       <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
    //         {originAddress}
    //       </div>
    //     </div>
    //     <div className="w-full">{/* <DistinationLine /> */}</div>
    //     <div className="flex flex-col items-end justify-start text-right">
    //       <div className="relative text-3xl font-medium text-basic dark:text-dark-primary">
    //         {destinationCity}
    //       </div>
    //       <div className="self-stretch relative font-medium text-basic dark:text-dark-primary text-base">
    //         {destinationAddress}
    //       </div>
    //     </div>
    //   </div>

    //   <div className="flex flex-col md:flex-col w-full bg-basic gap-2 rounded-b-2xl py-5 px-8 ">
    //     <div className="flex-1 pd-4 text-white">
    //       <div className="self-stretch flex flex-row items-center justify-between gap-x-3">
    //         <div className="relative font-semibold text-base">
    //           {moment(Pick_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
    //         </div>
    //         <div className="relative font-semibold text-base">
    //           {moment(Drop_Date_Time).tz("UTC").format("YYYY-MM-DD HH:mm")}
    //         </div>
    //       </div>
    //       <div className="self-stretch flex flex-row items-start justify-between">
    //         <div className="relative font-semibold text-base">
    //           With Driver: {withDriver}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default CarCard;
